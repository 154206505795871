import styled from 'styled-components';

import UI from '#front-credpago';

import globals from '../globals';

const { containerMaxWidth, fonts, colors } = globals;

export const MainContainer = styled.div`
  width: 100%;
  max-width: calc(${containerMaxWidth} + 40px);
  margin: 30px auto;
  align-self: center;
  position: relative;

  p {
    color: ${colors.lightGray};
    font-family: ${fonts.sans};
    font-size: 15px;
  }
`;

export const MainContainerSection = styled(UI.Row)`
  h4 {
    margin-bottom: 33px;
  }

  .content {
    p {
      font-family: ${globals.fonts.sans};
      line-height: 20px;
    }

    .subsection {
      margin-top: 33px;
      margin-left: 60px;

      h5 {
        color: #707070;
        margin-bottom: 33px;
      }
    }

    ul {
      list-style-type: '· ';
      list-style-position: outside;
    }

    a {
      color: ${colors.lightBlue};
    }
  }

  table,
  th,
  td {
    border: 1px solid #cccccc;
    border-collapse: collapse;
    padding: 8px;

    font-family: ${globals.fonts.sans};
    color: ${colors.lightGray};
  }

  @media ${UI.globals.mediaqueries.xs}, ${UI.globals.mediaqueries.sm} {
    .content {
      .subsection {
        margin-left: 0;
      }
    }
  }
`;
