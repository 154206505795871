import React from 'react';

import Layout from '../components/layout';
import MainContent from '../components/privacyPolicy/main';
import SEO from '../components/seo';

const PoliticaDePrivacidade = () => {
  return (
    <Layout internal={true} displayImobsParceiras={false} hasChatButton={true}>
      <SEO title="Política de Privacidade" />
      <MainContent />
    </Layout>
  );
};

export default PoliticaDePrivacidade;
