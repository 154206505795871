import React from 'react';

import UI from '#front-credpago';

import * as S from './styles';

const MainContent = () => {
  return (
    <S.MainContainer>
      <S.MainContainerSection>
        <UI.Col>
          <UI.Title
            level={1}
            fontWeight={700}
            splitLabel={['Política de ', 'Privacidade']}
            style={{ textAlign: 'left', marginBottom: 0 }}
          />
        </UI.Col>

        <UI.Col>
          <div className="content">
            <p>
              A <strong>CredPago</strong> (CredPago Serviços e Cobranças S.A. – <i>25.027.928/0001-90</i>) está
              comprometida em resguardar sua privacidade e proteger seus dados pessoais. Essa Política de Privacidade
              (“Política”) busca esclarecer como coletamos, usamos, compartilhamos e armazenamos suas informações e
              documentos.
            </p>
          </div>
        </UI.Col>

        <UI.Col>
          <UI.Title level={4} fontWeight={700}>
            PORQUE A CREDPAGO TRATA DADOS PESSOAIS?
          </UI.Title>

          <div className="content">
            <p>
              A CredPago surgiu para reinventar e desburocratizar o mercado imobiliário, proporcionando às imobiliárias,
              inquilinos e proprietários de imóveis mais facilidade e agilidade nas negociações. Por meio dos nossos
              serviços, imobiliárias e corretores podem negociar dívidas, gerenciar todo o processo de locação
              imobiliária, fornecer garantia locatícia aos inquilinos, e assinar contratos digitalmente.
            </p>
            <p>
              A fim de fornecer nossos produtos e serviços precisamos tratar os seus dados pessoais para diversas
              finalidades. Dentre os exemplos estão pagar inadimplências e realizar análise de crédito.
            </p>
            <p>
              Nosso compromisso com a confiança em nossas relações também se estende à proteção dos seus dados pessoais
              e da sua privacidade. A CredPago entende que o início da construção dessa confiança é exatamente no seu
              primeiro contato com a empresa e, por isso, destacamos nossa responsabilidade com você em cumprir todas as
              leis aplicáveis e seguir as melhores práticas.
            </p>
          </div>
        </UI.Col>

        <UI.Col>
          <UI.Title level={4} fontWeight={700}>
            1. O QUE PRECISO SABER PARA COMPREENDER ESTA POLÍTICA?
          </UI.Title>

          <div className="content">
            <p>Para auxiliar a leitura desta Política, apresentamos algumas definições úteis de termos que usamos:</p>
          </div>

          <div className="content">
            <table>
              <thead>
                <tr>
                  <td>
                    <strong>Termo</strong>
                  </td>
                  <td>
                    <strong>Conceito</strong>
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <strong>Bases Legais</strong>
                  </td>
                  <td>
                    São as hipóteses legais que autorizam a CredPago a Tratar dados pessoais. Todo e qualquer Tratamento
                    de dados pessoais só pode ser considerado válido se tiver fundamento em uma base legal.
                  </td>
                </tr>

                <tr>
                  <td>
                    <strong>Dado Pessoal ou Dado</strong>
                  </td>
                  <td>
                    É o dado relativo a uma pessoa física, que seja capaz de identificá-la ou torná-la identificável
                    dentro de determinado contexto. Podem ser citados como exemplos: nome, CPF, RG, endereço, telefone
                    celular e e-mail.
                  </td>
                </tr>

                <tr>
                  <td>
                    <strong>Titular dos Dados ou Titular</strong>
                  </td>
                  <td>É a pessoa a quem os dados pessoais estão relacionados.</td>
                </tr>

                <tr>
                  <td>
                    <strong>Tratamento ou Tratar</strong>
                  </td>
                  <td>
                    São os usos que a CredPago faz dos dados pessoais, incluindo, sem limitação, as seguintes
                    atividades: coleta, armazenamento, consulta, uso, compartilhamento, classificação, reprodução,
                    processamento e avaliação.
                  </td>
                </tr>

                <tr>
                  <td>
                    <strong>Você</strong>
                  </td>
                  <td>
                    É a pessoa física, com plena capacidade de contratar, que utiliza os serviços oferecidos pela
                    CredPago e/ou acessa o Website da CredPago, tais como usuários do Website, e corretores, inquilinos
                    e proprietários de imóveis.
                  </td>
                </tr>

                <tr>
                  <td>
                    <strong>Website ou Plataforma</strong>
                  </td>
                  <td>
                    É o site da CredPago, disponível em{' '}
                    <a href="https://credpago.com.br/" target="_blank" rel="noreferrer">
                      https://credpago.com.br/
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </UI.Col>

        <UI.Col>
          <UI.Title level={4} fontWeight={700}>
            2. QUAIS DADOS SÃO COLETADOS E PARA QUAL FINALIDADE?
          </UI.Title>

          <div className="content">
            <p>
              A CredPago pode coletar dados sobre você em diferentes situações. Em geral, é possível que você nos
              forneça dados diretamente; que dados sobre as suas interações conosco sejam registrados em nossos
              sistemas; e que o seu uso dos nossos serviços também gere registros sobre as suas atividades em nossas
              plataformas digitais.
            </p>

            <p>
              Nós também podemos coletar dados em fontes publicamente acessíveis ou junto a terceiros autorizados e
              contratados pela CredPago sujeitos a obrigações de confidencialidade e sempre cumprindo os requisitos da
              legislação brasileira. Fazemos isso para obter, validar e complementar dados relacionados aos nossos
              clientes e clientes em potencial com o objetivo de garantir a qualidade e a exatidão dos dados que
              mantemos. Tais práticas nos permitem aprimorar a segurança dos nossos serviços e a assertividade de nossas
              iniciativas de comunicação, melhorando as chances de estabelecermos contato com você quando necessário,
              inclusive quando for relevante para ofertar nossos serviços, se entendermos que algo que temos a oferecer
              pode ser do seu interesse.
            </p>

            <p>
              Na prática, o tipo, a quantidade e a forma com que coletamos seus dados pessoais dependem da sua relação
              com a CredPago. Por exemplo, os dados coletados no momento de cadastro no Website da CredPago são
              diferentes daqueles que precisamos para fornecer uma garantia locatícia. Para tornar essas diferenças mais
              claras, listamos abaixo algumas das principais finalidades para as quais seus dados podem ser utilizados
              dependendo do tipo de interação que você tem conosco:
            </p>
          </div>
        </UI.Col>

        <UI.Col>
          <UI.Title level={5} fontWeight={700}>
            2.1. Visitante do Website
          </UI.Title>

          <div className="content">
            <p>
              Se você é um visitante e/ou interagiu com nosso Website, seus dados pessoais serão utilizados nas formas
              apresentadas na tabela abaixo:
            </p>
          </div>

          <div className="content">
            <table>
              <thead>
                <tr>
                  <td>
                    <strong>Finalidade</strong>
                  </td>
                  <td>
                    <strong>Tipos de dados coletados</strong>
                  </td>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>
                    <strong>Acesso ao Website e personalização de conteúdo</strong>
                  </td>
                  <td>
                    <strong>Dados de navegação</strong>: número de IP, data, hora e duração do acesso.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </UI.Col>

        <UI.Col>
          <UI.Title level={5} fontWeight={700}>
            2.2. Corretor ou gestor de imobiliária
          </UI.Title>

          <div className="content">
            <p>
              Se você é um corretor ou gestor de imobiliária, além da forma de utilização descrita no item 2.1 acima, no
              que for aplicável, seus dados pessoais também poderão ser utilizados de acordo com a descrição da tabela
              abaixo:
            </p>
          </div>

          <div className="content">
            <table>
              <thead>
                <tr>
                  <td>
                    <strong>Finalidade</strong>
                  </td>
                  <td>
                    <strong>Tipos de dados coletados</strong>
                  </td>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>
                    <strong>Contato para início ou continuação de parceria</strong>
                  </td>
                  <td>
                    <strong>Dados cadastrais</strong>: nome, CPF, telefone e e-mail.
                  </td>
                </tr>

                <tr>
                  <td>
                    <strong>Realização de processo de onboarding</strong>
                  </td>
                  <td>
                    <strong>Dados cadastrais</strong>: nome, CPF, telefone e e-mail.
                  </td>
                </tr>

                <tr>
                  <td>
                    <strong>Realização de cadastro</strong>
                  </td>
                  <td>
                    <strong>Dados cadastrais</strong>: nome, e-mail, telefone, CPF e endereço.
                    <br />
                    <strong>Dados bancários</strong>: banco, agência e conta.
                  </td>
                </tr>

                <tr>
                  <td>
                    <strong>Assinatura de termos por meio de plataforma digital</strong>
                  </td>
                  <td>
                    <strong>Dados cadastrais</strong>: nome e e-mail.
                  </td>
                </tr>

                <tr>
                  <td>
                    <strong>Acompanhamento e gestão dos usuários das plataformas CredPago</strong>
                  </td>
                  <td>
                    <strong>Dados cadastrais</strong>: nome, e-mail e telefone.
                    <br />
                    <strong>Dados da conta</strong>: ID do usuário e senha.
                  </td>
                </tr>

                <tr>
                  <td>
                    <strong>
                      Esclarecimento de dúvidas, solicitações e acompanhamento de execução de processos por meio de
                      canais de atendimento
                    </strong>
                  </td>
                  <td>
                    <strong>Dados cadastrais do corretor e do proprietário do imóvel</strong>
                    : nome, data de nascimento, endereço, CPF, telefone e e-mail.
                    <br />
                    <strong>Dados do imóvel</strong>: contrato de locação e vistoria de entrada e saída.{' '}
                  </td>
                </tr>

                <tr>
                  <td>
                    <strong>Cobrança de pagamentos e taxas</strong>
                  </td>
                  <td>
                    <strong>Dados cadastrais</strong>: nome, endereço, CPF, RG, estado civil, nacionalidade e formação.
                    <br />
                    <strong>Dados bancários</strong>: banco, agência e conta.
                    <br />
                    <strong>Dados do imóvel</strong>: tipo de imóvel e localização.
                  </td>
                </tr>

                <tr>
                  <td>
                    <strong>Realização de pagamento</strong>
                  </td>
                  <td>
                    <strong>Dados cadastrais do fornecedor ou de terceiro autorizado</strong>
                    : nome, CPF, estado civil e endereço.
                    <br />
                    <strong>Dados bancários do fornecedor ou de terceiro autorizado</strong>: banco, agência e conta.
                  </td>
                </tr>

                <tr>
                  <td>
                    <strong>Conferência de contas a receber</strong>
                  </td>
                  <td>
                    <strong>Dados cadastrais</strong>: nome e CPF.
                  </td>
                </tr>

                <tr>
                  <td>
                    <strong>Análise dos comunicados de inadimplência</strong>
                  </td>
                  <td>
                    <strong>Dados cadastrais</strong>: nome e CPF.
                    <br />
                    <strong>Dados do imóvel</strong>: endereço do imóvel.
                    <br />
                    <strong>Dados bancários</strong>: banco, agência e conta.
                  </td>
                </tr>

                <tr>
                  <td>
                    <strong>Monitoramento e auditoria dos portais CredPago</strong>
                  </td>
                  <td>
                    <strong>Dados de navegação</strong>: número de IP, geolocalização, e histórico de navegação.
                    <br />
                    <strong>Dados do aparelho</strong>: modelo de aparelho e versão do sistema operacional.
                  </td>
                </tr>

                <tr>
                  <td>
                    <strong>Pagamentos de comissões e premiações</strong>
                  </td>
                  <td>
                    <strong>Dados cadastrais</strong>: nome, endereço, CPF, e-mail, telefone.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </UI.Col>

        <UI.Col>
          <UI.Title level={5} fontWeight={700}>
            2.3. Inquilino
          </UI.Title>

          <div className="content">
            <p>
              Se você é um inquilino, além da forma de utilização descrita no item 2.1 acima no que for aplicável, seus
              dados pessoais serão utilizados nas formas apresentada na tabela abaixo:
            </p>
          </div>

          <div className="content">
            <table>
              <thead>
                <tr>
                  <td>
                    <strong>Finalidade</strong>
                  </td>
                  <td>
                    <strong>Tipos de dados coletados</strong>
                  </td>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>
                    <strong>Renovação de contratos</strong>
                  </td>
                  <td>
                    <strong>Dados cadastrais</strong>: nome, telefone, endereço, data de nascimento e e-mail.
                  </td>
                </tr>

                <tr>
                  <td>
                    <strong>Análise de crédito</strong>
                  </td>
                  <td>
                    <strong>Dados cadastrais do inquilino e do corresponsável</strong>
                    : nome, CPF, idade.
                    <br />
                    <strong>Dados financeiros e relacionados à renda do inquilino e do corresponsável</strong>
                    : perfil de consumo, score de crédito, holerites, IRPF, extrato bancário, extrato de investimentos,
                    certidão de dívidas, informações de cartão de crédito (limite, gastos e pagamentos).
                    <br />
                    <strong>Dados adicionais</strong>: informações sobre vínculos judiciais e certidão de antecedentes
                    criminais.
                  </td>
                </tr>

                <tr>
                  <td>
                    <strong>Ativação de propostas de garantia locatícia</strong>
                  </td>
                  <td>
                    <strong>Dados cadastrais do inquilino e do corresponsável</strong>: nome, CPF, telefone, endereço,
                    data de nascimento e e-mail.
                  </td>
                </tr>

                <tr>
                  <td>
                    <strong>
                      Esclarecimento de dúvidas, reclamações, solicitações e acompanhamento de execução de processos por
                      meio de canais de atendimento
                    </strong>
                  </td>
                  <td>
                    <strong>Dados cadastrais do inquilino e do proprietário do imóvel</strong>
                    : nome, data de nascimento, endereço, CPF, telefone e e-mail.
                    <br />
                    <strong>Dados do imóvel</strong>: contrato de locação e vistoria de entrada e saída. <br />
                    <strong>Dados financeiros</strong>: nota fiscal e detalhamento de dívidas com a CredPago.
                  </td>
                </tr>

                <tr>
                  <td>
                    <strong>Comunicação via e-mail, telefone ou WhatsApp</strong>
                  </td>
                  <td>
                    <strong>Dados cadastrais</strong>: nome, telefone e e-mail.
                    <br />
                    <strong>Registro das comunicações</strong>: registro das mensagens, e-mails e gravação de ligações
                    telefônicas.{' '}
                  </td>
                </tr>

                <tr>
                  <td>
                    <strong>Análise da base de dados da CredPago</strong>
                  </td>
                  <td>
                    <strong>Dados cadastrais</strong>: nome, idade, data de nascimento, telefone, endereço e CPF.
                    <br />
                    <strong>Dados financeiros</strong>: score de crédito e inadimplências.
                  </td>
                </tr>

                <tr>
                  <td>
                    <strong>Cobrança de pagamentos e taxas</strong>
                  </td>
                  <td>
                    <strong>Dados cadastrais do inquilino, do corresponsável e do proprietário do imóvel</strong>
                    : nome, endereço, CPF, RG, estado civil, nacionalidade e formação.
                    <br />
                    <strong>Dados bancários do inquilino e do coresponsável</strong>
                    : banco, agência, conta e dados do cartão de crédito.
                    <br />
                    <strong>Dados do imóvel</strong>: tipo de imóvel e localização.
                  </td>
                </tr>

                <tr>
                  <td>
                    <strong>Emissão de nota fiscal</strong>
                  </td>
                  <td>
                    <strong>Dados cadastrais</strong>: nome, endereço e CPF.
                  </td>
                </tr>

                <tr>
                  <td>
                    <strong>Cancelamento de fiança e estorno de valores pagos</strong>
                  </td>
                  <td>
                    <strong>Dados cadastrais do inquilino e do corresponsável</strong>
                    : nome, CPF, telefone, endereço, data de nascimento e e-mail.
                    <br />
                    <strong>Dados bancários do inquilino e do corresponsável</strong>: banco, agência, conta e dados do
                    cartão de crédito.
                  </td>
                </tr>

                <tr>
                  <td>
                    <strong>Análise dos comunicados de inadimplência</strong>
                  </td>
                  <td>
                    <strong>Dados cadastrais</strong>: nome e CPF.
                    <br />
                    <strong>Dados do imóvel</strong>: endereço do imóvel.
                    <br />
                    <strong>Dados bancários</strong>: banco, agência e conta.
                  </td>
                </tr>

                <tr>
                  <td>
                    <strong>Monitoramento e auditoria dos portais CredPago</strong>
                  </td>
                  <td>
                    <strong>Dados de navegação</strong>: número de IP, geolocalização, e histórico de navegação.
                    <br />
                    <strong>Dados do aparelho</strong>: modelo de aparelho e versão do sistema operacional.
                  </td>
                </tr>

                <tr>
                  <td>
                    <strong>
                      Ações judiciais de recuperação de crédito, processos passivos e notificações extrajudiciais
                    </strong>
                  </td>
                  <td>
                    <strong>Dados cadastrais do inquilino e do corresponsável</strong>
                    : nome, CPF, data de nascimento, endereço, nacionalidade, estado civil e e-mail. <br />
                    <strong>Dados financeiros do inquilino e do corresponsável</strong>: boletos e comprovantes de
                    pagamento, detalhamento das dívidas com a CredPago, dados de cartões de crédito e score de crédito.
                  </td>
                </tr>

                <tr>
                  <td>
                    <strong>Análise de casos de possíveis fraudes</strong>
                  </td>
                  <td>
                    <strong>Dados cadastrais</strong>: nome, CPF, RG e-mail. <br />
                    <strong>Dados comprobatórios</strong>: boletins de ocorrência e fotos ou provas que demonstrem a
                    possível fraude.
                  </td>
                </tr>

                <tr>
                  <td>
                    <strong>Validação de identidade</strong>
                  </td>
                  <td>
                    <strong>Dados cadastrais</strong>: nome, CPF, fotografia e biometria facial.
                    <br />
                    <strong>Dados comprobatórios</strong>: tais dados serão validados perante uma base de dados públicos
                    e privados visando garantir a identidade do inquilino.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </UI.Col>

        <UI.Col>
          <UI.Title level={5} fontWeight={700}>
            3. COOKIES
          </UI.Title>

          <div className="content">
            <p>
              Utilizamos cookies e tecnologias similares, como pixels e tags, para certificar que os serviços prestados
              estão de acordo com o melhor padrão de desempenho esperado por você. Os cookies coletados pela CredPago
              fornecem somente estatísticas e não serão utilizados para propósitos diversos dos expressamente previstos
              nesta Política de Privacidade.
            </p>

            <p>Separamos abaixo algumas informações sobre essa ferramenta e como a utilizamos:</p>
          </div>

          <div className="content">
            <table>
              <tbody>
                <tr>
                  <td>
                    <strong>O que são?</strong>
                  </td>
                  <td>
                    Cookies são pequenos arquivos adicionado ao seu dispositivo ou computador para fornecer uma
                    experiência personalizada de acesso à CredPago.
                  </td>
                </tr>

                <tr>
                  <td>
                    <strong>Para que são utilizados?</strong>
                  </td>
                  <td>
                    A CredPago utiliza cookies para vários fins, incluindo:
                    <br />
                    <strong>(i)</strong> Autenticação dos usuários na plataforma e armazenamento de preferências e
                    funcionalidades.
                    <br />
                    <strong>(ii)</strong> Disponibilização de vídeos no Website;
                    <br />e <strong>(iii)</strong> Análise do comportamento de uso do Website para melhor
                    desenvolvimento dos nossos produtos.
                  </td>
                </tr>

                <tr>
                  <td>
                    <strong>É possível limitar a coleta?</strong>
                  </td>
                  <td>
                    Se você quiser saber quais cookies estão instalados no seu dispositivo, ou se deseja excluí-los ou
                    restringi-los, use as configurações do seu navegador para isso. Você encontrará mais explicações
                    sobre como proceder clicando nos links abaixo.
                    <br />
                    <a
                      href="https://support.mozilla.org/pt-BR/kb/limpe-cookies-e-dados-de-sites-no-firefox"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Firefox
                    </a>
                    ;
                    <br />
                    <a
                      href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=pt-BR"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Chrome
                    </a>
                    ;
                    <br />
                    <a
                      href="https://support.apple.com/pt-br/guide/safari/sfri11471/mac"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Safari
                    </a>
                    ;
                    <br />
                    <a
                      href="https://support.microsoft.com/pt-br/topic/como-excluir-arquivos-de-cookie-no-internet-explorer-bca9446f-d873-78de-77ba-d42645fa52fc"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Internet Explorer
                    </a>
                    .
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="content">
            <p>
              Lembre-se que o uso de cookies nos permite oferecer a você uma melhor experiência. Caso você exclua ou
              restrinja os cookies, não temos como garantir o correto funcionamento de todas as funcionalidades da nossa
              plataforma. Além disso, é provável que certas funções e páginas não funcionem da maneira adequada.
            </p>

            <p>
              Importante esclarecer que a CredPago não se responsabiliza pelo uso de cookies por terceiros. Fique
              atento, pois os cookies colocados por terceiros podem eventualmente continuar a monitorar as suas
              atividades online mesmo depois de você ter saído de nosso Website. Recomendável que você limpe seus dados
              de navegação regularmente.
            </p>
          </div>
        </UI.Col>

        <UI.Col>
          <UI.Title level={5} fontWeight={700}>
            4. MENORES DE IDADE
          </UI.Title>

          <div className="content">
            <p>
              Os serviços oferecidos pela CredPago não são direcionados a menores de 18 (dezoito) anos. Por isso, nós
              não coletamos ou conscientemente solicitamos informações de menores de 18 (dezoito) anos em nossas
              operações. Indivíduos menores de 18 (dezoito) anos não devem tentar ter acesso aos nossos serviços ou nos
              enviar qualquer informação. Se tomarmos conhecimento de que coletamos informações de um indivíduo menor de
              18 (dezoito) anos, eliminaremos os dados pessoais relacionados.
            </p>
          </div>
        </UI.Col>

        <UI.Col>
          <UI.Title level={5} fontWeight={700}>
            5. COMO E POR QUANTO TEMPO ARMAZENAMOS OS DADOS COLETADOS?
          </UI.Title>

          <div className="content">
            <p>
              A segurança dos dados pessoais é uma das prioridades da CredPago. Buscamos o constante aprimoramento dos
              nossos processos e a adoção das melhores práticas de Segurança da Informação para proteger você e os dados
              a que temos acesso.
              <br />
              <br /> Assim, a nossa <i>Política de Retenção de Dados Pessoais</i> determina que os dados pessoais serão
              armazenados somente pelo tempo que for necessário para cumprir com as finalidades para as quais foram
              coletados. Assim, fazemos uma análise técnica para determinar o período de retenção adequado para cada
              tipo de dado coletado, considerando a sua natureza, a necessidade de coleta e finalidade.
            </p>
          </div>
        </UI.Col>

        <UI.Col>
          <UI.Title level={5} fontWeight={700}>
            6. COM QUEM A CREDPAGO COMPARTILHA SEUS DADOS PESSOAIS?
          </UI.Title>

          <div className="content">
            <p>
              Aqui na CredPago temos parceria com diversas empresas e organizações para auxiliar em nossas atividades.
              Desse modo, é possível que dados pessoais sejam compartilhados com nossos parceiros e, para atendermos às
              exigências legais, também com entidades públicas e autoridades competentes.
            </p>

            <p>
              Para ilustrar essas relações, indicamos aqui algumas hipóteses em que a CredPago poderá compartilhar seus
              dados pessoais:
            </p>
          </div>

          <div className="content">
            <table>
              <tbody>
                <tr>
                  <td>
                    <strong>Nossos fornecedores e parceiros.</strong> Temos uma série de fornecedores e parceiros (como
                    por exemplo, empresas de dados, escritórios jurídicos, escritórios que prestam serviços de cobrança
                    etc.) que precisamos contratar para viabilizar a nossa operação. Assim, a CredPago poderá
                    compartilhar dados coletados com esses parceiros na medida em que tais informações sejam necessárias
                    à prestação do serviço, a qual é feita sob a nossa orientação. Sempre buscamos avaliar
                    cuidadosamente nossos fornecedores e firmar com eles obrigações contratuais de segurança da
                    informação e proteção de dados pessoais com o objetivo de minimizar riscos.
                  </td>
                </tr>

                <tr>
                  <td>
                    <strong>Empresas do mesmo grupo econômico</strong>. Os dados coletados poderão ser compartilhados
                    com empresas que façam parte do mesmo grupo econômico e/ou estrutura societária da CredPago, desde
                    que tal compartilhamento seja necessário para fins de operação, manutenção e/ou melhoria na
                    prestação dos serviços oferecidos. Nesse contexto, qualquer empresa que venha a ter acesso aos dados
                    deverá adotar pelo menos os mesmos níveis de segurança utilizados e garantidos pela CredPago.
                  </td>
                </tr>

                <tr>
                  <td>
                    <strong>Autoridades Públicas</strong>. Somos obrigados a cumprir a lei. Assim, se estamos sujeitos a
                    alguma obrigação legal ou regulatória que determine o fornecimento de dados pessoais a alguma
                    autoridade, somos obrigados a fazê-lo. Além disso, se um juiz ou uma autoridade com competência
                    legal ou regulatória exigir que a CredPago compartilhe certos dados pessoais para, por exemplo,
                    subsidiar uma investigação em curso, também podemos ser obrigados a compartilhar. Caso a CredPago
                    entenda que determinada ordem é abusiva ou excessiva, vamos sempre defender o direito à privacidade
                    e à proteção dos dados que estão sob o nosso controle.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </UI.Col>

        <UI.Col>
          <UI.Title level={5} fontWeight={700}>
            7. QUAIS SÃO SEUS DIREITOS COMO TITULAR DE DADOS PESSOAIS?
          </UI.Title>

          <div className="content">
            <p>
              A lei brasileira garante uma série de direitos aos titulares de dados pessoais que devem ser observados
              por todos aqueles que tratam dessas informações. Nesta seção vamos explicar quais são esses direitos e
              como eles poderão ser exercidos perante a CredPago.
            </p>
          </div>

          <div className="content">
            <table>
              <thead>
                <tr>
                  <td>
                    <strong>Seus Direitos</strong>
                  </td>
                  <td>
                    <strong>Explicação</strong>
                  </td>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>
                    <strong>Anonimização, bloqueio ou eliminação</strong>
                  </td>
                  <td>
                    Você pode solicitar <strong>(a)</strong> a anonimização dos seus dados pessoais, de forma que eles
                    não possam mais ser relacionados a você e, portanto, deixem de ser dados pessoais;{' '}
                    <strong>(b)</strong> o bloqueio dos seus dados pessoais, suspendendo temporariamente a possibilidade
                    de tratamento para certas finalidades; e <strong>(c)</strong> a eliminação dos seus dados pessoais,
                    caso em que devemos apagar todos os seus dados pessoais da nossa base sem possibilidade de reversão.
                  </td>
                </tr>

                <tr>
                  <td>
                    <strong>Confirmação e Acesso</strong>
                  </td>
                  <td>
                    Você pode solicitar à CredPago a confirmação sobre a existência de tratamento dos seus dados
                    pessoais para que, em caso positivo, você possa acessá-los, inclusive por meio de solicitação de
                    cópias dos registros que temos sobre você.
                  </td>
                </tr>

                <tr>
                  <td>
                    <strong>Correção</strong>
                  </td>
                  <td>
                    Você pode solicitar a correção dos seus dados pessoais caso estes estejam incompletos, inexatos ou
                    desatualizados.
                  </td>
                </tr>

                <tr>
                  <td>
                    <strong>Informação sobre a possibilidade de não consentir</strong>
                  </td>
                  <td>
                    Você tem o direito de receber informações claras e completas sobre a possibilidade e as
                    consequências de não fornecer consentimento, quando ele for solicitado pela CredPago. O seu
                    consentimento, quando necessário, deve ser livre e informado. Portanto, sempre que pedirmos seu
                    consentimento, você será livre para negá-lo – nesses casos, é possível que alguns serviços não
                    possam ser prestados.
                  </td>
                </tr>

                <tr>
                  <td>
                    <strong>Informação sobre o compartilhamento</strong>
                  </td>
                  <td>
                    Você tem o direito de saber quais são as entidades públicas e privadas com as quais a CredPago
                    realiza uso compartilhado dos seus dados pessoais. Manteremos no item 4 desta Política uma indicação
                    das nossas relações com terceiros que podem envolver o compartilhamento de dados pessoais. Em todo
                    caso, se você tiver dúvidas ou quiser mais detalhes, você tem o direito de nos solicitar essas
                    informações. A depender da situação, podemos limitar as informações fornecidas a você, caso a sua
                    divulgação possa violar a propriedade intelectual ou segredo de negócios da CredPago.
                  </td>
                </tr>

                <tr>
                  <td>
                    <strong>Oposição</strong>
                  </td>
                  <td>
                    A lei autoriza o tratamento de dados pessoais mesmo sem o seu consentimento ou um contrato conosco.
                    Nessas situações, somente trataremos seus dados pessoais se tivermos motivos legítimos para tanto.
                    Caso você não concorde com alguma finalidade de tratamento dos seus dados pessoais, você poderá
                    apresentar oposição, solicitando a sua interrupção
                  </td>
                </tr>

                <tr>
                  <td>
                    <strong>Portabilidade</strong>
                  </td>
                  <td>
                    Você pode solicitar que a CredPago forneça os seus dados pessoais em formato estruturado e
                    interoperável visando à sua transferência para um terceiro, desde que essa transferência não viole a
                    propriedade intelectual ou segredo de negócios da CredPago.
                  </td>
                </tr>

                <tr>
                  <td>
                    <strong>Revogação do consentimento</strong>
                  </td>
                  <td>
                    Caso você tenha consentido com alguma finalidade de tratamento dos seus dados pessoais, você pode
                    sempre optar por retirar o seu consentimento. No entanto, isso não afetará a legalidade de qualquer
                    tratamento realizado anteriormente à revogação. Se você retirar o seu consentimento, é possível que
                    fiquemos impossibilitados de lhe prestar certos serviços, mas iremos avisá-lo quando isso ocorrer.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="content">
            <p>
              Para solicitar o exercício de qualquer um desses direitos listados acima, favor entrar em contato conosco
              pelo canal{' '}
              <a href="https://loft.com.br/institucional/portal-de-denuncias" target="_blank" rel="noreferrer">
                Portal de Denúncias
              </a>
              .
            </p>

            <p>
              Para sua segurança, sempre que você apresentar uma requisição para exercer seus direitos, a CredPago
              poderá solicitar algumas informações e/ou documentos complementares para que possamos comprovar a sua
              identidade, buscando impedir fraudes. Fazemos isso para garantir a segurança e a privacidade de todos
              visando à não divulgação de dados pessoais a pessoas não autorizadas. <br />
              Ainda, algumas solicitações podem não ser respondidas de forma imediata, mas a CredPago se compromete a
              responder todas as requisições em um prazo razoável e sempre em conformidade com a legislação aplicável.
            </p>
          </div>
        </UI.Col>

        <UI.Col>
          <UI.Title level={5} fontWeight={700}>
            8. COMO FALAR SOBRE DADOS PESSOAIS COM A CREDPAGO?
          </UI.Title>

          <div className="content">
            <p>
              Se você acredita que seus dados pessoais foram tratados de maneira incompatível com esta Política, com as
              suas escolhas enquanto titular dos seus dados pessoais ou ainda, se você tiver dúvidas, comentários ou
              sugestões relacionadas a esta Política e à forma como tratamos seus dados pessoais, entre em contato
              conosco. Nós temos um Encarregado (<i>Data Protection Officer – DPO</i>) que está à disposição através do
              canal{' '}
              <a href="https://loft.com.br/institucional/portal-de-denuncias" target="_blank" rel="noreferrer">
                Portal de Denúncias
              </a>
              .
            </p>
          </div>
        </UI.Col>

        <UI.Col>
          <UI.Title level={5} fontWeight={700}>
            9. MUDANÇAS NA POLÍTICA DE PRIVACIDADE
          </UI.Title>

          <div className="content">
            <p>
              Como estamos sempre buscando melhorar os nossos serviços e a forma como operamos, esta Política de
              Privacidade pode passar por atualizações para refletir as melhorias realizadas. Desta forma, recomendamos
              a visita periódica desta página para que você tenha conhecimento sobre as modificações.
            </p>
          </div>
        </UI.Col>

        <UI.Col>
          <div className="content">
            <p>DATA DA ÚLTIMA ATUALIZAÇÃO: 05 de Janeiro de 2023</p>
          </div>
        </UI.Col>
      </S.MainContainerSection>
    </S.MainContainer>
  );
};

export default MainContent;
